import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient,
              private authService: AuthService) {
  }

  get(url) {

    let authToken = '';
    if (this.authService.authToken) {
      authToken = this.authService.authToken;
    }

    return this.httpClient.get(url, {
      headers: {
        Authorization: authToken
      }
    }).toPromise() as any;
  }

  put(url, body) {

    let authToken = '';
    if (this.authService.authToken) {
      authToken = this.authService.authToken;
    }

    return this.httpClient.put(url, body, {
      headers: {
        Authorization: authToken
      }
    }).toPromise() as any;
  }

  post(url, body) {

    let authToken = '';
    if (this.authService.authToken) {
      authToken = this.authService.authToken;
    }

    return this.httpClient.post(url, body, {
      headers: {
        Authorization: authToken
      }
    }).toPromise() as any;
  }

  delete(url) {

    let authToken = '';
    if (this.authService.authToken) {
      authToken = this.authService.authToken;
    }

    return this.httpClient.delete(url, {
      headers: {
        Authorization: authToken
      }
    }).toPromise() as any;
  }

}
