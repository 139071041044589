import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService,
              private userService: UserService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {

      console.log('TRY');

      if (this.authService.authToken) {

        this.userService.getSelf()
          .then((user) => {
            this.userService.user = user;
            resolve(true);
          })
          .catch((err) => {
            this.router.navigate(['/login']);
            resolve(false);
          });

      } else {
        console.log('REJECT');
        resolve(false);
      }

    });

  }

}
