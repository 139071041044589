import { Injectable } from '@angular/core';
import { IUser } from './user.service';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {

  }

  get authToken() {
    return localStorage.getItem('authToken');
  }

  set authToken(token) {
    localStorage.setItem('authToken', token);
  }

}
