import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './shared/guards/admin.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'aktivnosti',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'aktivnost/:id',
    loadChildren: () => import('./event/event.module').then(m => m.EventModule)
  },
  {
    path: 'nasvet/:id',
    loadChildren: () => import('./article/article.module').then(m => m.ArticleModule)
  },
  {
    path: 'nasveti',
    loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
  },
  {
    path: 'svetovalnica',
    loadChildren: () => import('./svetovalnica/svetovalnica.module').then(m => m.SvetovalnicaModule)
  },
  {
    path: 'iskalnik',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
