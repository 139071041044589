import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: IUser = {};

  constructor(private apiService: ApiService,
              private authService: AuthService,
              private router: Router) {
  }

  createUser(user: IUser) {
    return this.apiService.post(`${environment.serverUrl}/v1/users/register`, user);
  }

  async loginWithEmailAndPassword(email, password) {

    try {
      const user = (await this.apiService.post(`${environment.serverUrl}/v1/users/login`, {
        email, password
      })) as any;

      this.authService.authToken = user.data.accessToken;
      this.user = await this.getSelf();
      this.router.navigate(['/admin/nasveti']);
    } catch (e) {
      alert('Pri prijavi je prišlo do napake. Preveri pravilnost podatkov in poizusi ponovno ali kontaktiraj administratorja.');
    }
  }

  async logout() {
    this.authService.authToken = null;
    this.router.navigate(['/']);
  }

  async getSelf() {
    return (await this.apiService.get(`${environment.serverUrl}/v1/users/self`)).data;
  }

  async getUser(id) {
    return (await this.apiService.get(`${environment.serverUrl}/v1/users/${id}`)).data;
  }

  async updateSelf(user: IUser) {
    return (await this.apiService.put(`${environment.serverUrl}/v1/users/self`, user)).data;
  }

  async updateUser(user: IUser) {
    return (await this.apiService.put(`${environment.serverUrl}/v1/users/${user._id}`, user)).data;
  }

  async updateOwnPassword(password) {
    return (await this.apiService.put(`${environment.serverUrl}/v1/users/self/password`, {password})).data;
  }

  async getUsers(): Promise<Array<IUser>> {
    return (await this.apiService.get(`${environment.serverUrl}/v1/users`)).data;
  }

  async deleteUser(id): Promise<Array<IUser>> {
    return (await this.apiService.delete(`${environment.serverUrl}/v1/users/${id}`)).data;
  }

}

export interface IUser {

  _id?: string;
  name?: string;
  clientUid?: string;
  nickname?: string;
  email?: string;
  password?: string;
  role?: USER_ROLE;
  createdAt?: Date;

}

export enum USER_ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER'
}
